import axios, { AxiosInstance } from "axios";
import { SurfSpot } from "@/models/surf-spot";
import { SpotReport } from "@/models/spot-report";
import { LoginResponse } from "@/models/login-response";
import { WaveForecast } from "@/models/wave-forecast";
import { TideForecast } from "@/models/tide-forecast";
import { WindForecast } from "@/models/wind-forecast";
import axiosConvertDateInterceptor from "./axios-convert-dates-interceptor";

class WebApiClient {

    axiosInstance: AxiosInstance;

    // baseURL: process.env.VUE_APP_API_PREFIX || process.env.STORYBOOK_API_PREFIX,
    constructor(baseURL: string) {
        this.axiosInstance = axios.create({ baseURL });
        this.axiosInstance.interceptors.response.use(axiosConvertDateInterceptor);
    }

    setJwt = (jwt: string | undefined) => {
        delete this.axiosInstance.defaults.headers.common["Authorization"];
        if (jwt) {
            this.axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
        }
    }

    login = async (username: string, password: string): Promise<LoginResponse> => {
        const response = await this.axiosInstance.post("/Login", { username, password });
        return response.data;
    }

    getSurfSpots = async (north: number, south: number, east: number, west: number): Promise<SurfSpot[]> => {
        const response = await this.axiosInstance.get("/SurfSpots", { params: { north, south, east, west } });
        return response.data;
    }

    getSpotReport = async (spotId: string): Promise<SpotReport> => {
        const response = await this.axiosInstance.get("/SpotReport", { params: { spotId } });
        return response.data;
    }

    getWaveForecast = async (spotId: string): Promise<WaveForecast[]> => {
        const response = await this.axiosInstance.get("/WaveForecast", { params: { spotId } });
        return response.data;
    }

    getWindForecast = async (spotId: string): Promise<WindForecast[]> => {
        const response = await this.axiosInstance.get("/WindForecast", { params: { spotId } });
        return response.data;
    }

    getTideForecast = async (spotId: string): Promise<TideForecast[]> => {
        const response = await this.axiosInstance.get("/TideForecast", { params: { spotId } });
        return response.data;
    }
}

export {
    WebApiClient
}