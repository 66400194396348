<template>
    <div class="skeleton"></div>
</template>
<style lang="scss" scoped>
.skeleton {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 1em;
    background-color: $light-grey;
    background-clip: content-box;
    border: 1px solid transparent;
    border-radius: $border-radius-sm;
    overflow: hidden;
}

.skeleton::after {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    background-image: linear-gradient(90deg,
            rgba($white, 0.0) 0%,
            rgba($white, 0.5) 25%,
            rgba($white, 0.5) 75%,
            rgba($white, 0.0) 100%,
        );
    transform: translateX(-100%);
    animation: shimmer 2s ease-in-out infinite;
}

@keyframes shimmer {
    100% {
        transform: translateX(50%);
    }
}
</style>