import { SurfQuality } from "@/models/surf-quality";

const surfQualityToCssClassName = (quality: SurfQuality | undefined): string => {
    switch (quality) {
        case SurfQuality.EXPIRED:
        case SurfQuality.FLAT:
            return "flat";
        case SurfQuality.VERY_POOR:
        case SurfQuality.POOR:
        case SurfQuality.POOR_TO_FAIR:
            return "poor";
        case SurfQuality.FAIR:
        case SurfQuality.FAIR_TO_GOOD:
            return "fair";
        case SurfQuality.GOOD:
        case SurfQuality.VERY_GOOD:
        case SurfQuality.GOOD_TO_EPIC:
            return "good";
        case SurfQuality.EPIC:
            return "epic";
        default:
            return "flat";
    }
}

export {
    surfQualityToCssClassName
}