<template>
    <div class="login-page-layout">
        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.login-page-layout {
    // Size to viewport
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-blue;
    padding: 0 $spacer-2;
}

.container {
    flex-grow: 1;
    max-width: $container-width;
}
</style>
