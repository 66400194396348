export enum SurfQuality {
    UNKNOWN = "UNKNOWN",
    EXPIRED = "EXPIRED",
    FLAT = "FLAT",
    VERY_POOR = "VERY_POOR",
    POOR = "POOR",
    POOR_TO_FAIR = "POOR_TO_FAIR",
    FAIR = "FAIR",
    FAIR_TO_GOOD = "FAIR_TO_GOOD",
    GOOD = "GOOD",
    VERY_GOOD = "VERY_GOOD",
    GOOD_TO_EPIC = "GOOD_TO_EPIC",
    EPIC = "EPIC",
}