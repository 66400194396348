import { createRouter, createWebHistory, RouteLocation, RouteLocationRaw, RouteRecordRaw } from "vue-router"
import Home from "../views/home.vue";
import Login from "../views/login.vue";
import { trackRouter } from "vue-gtag-next";
import _get from "lodash/get";
import { useStore } from "@/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { allowAnonymous: true }
    },
    {
        path: "/logout",
        name: "logout",
        redirect(): RouteLocationRaw {
            const store = useStore();
            store.jwt = undefined;
            return { name: "login", replace: true };
        }
    },
    {
        path: "/s/:slug",
        redirect(to: RouteLocation): RouteLocationRaw {
            const store = useStore();
            const slug = _get(to, "params.slug", "").toLowerCase();
            store.pendingSurfSpotSlug = slug;
            return { name: "home", replace: true };
        }
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/"
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const store = useStore();
    if (!to.meta.allowAnonymous && store.jwt === undefined) {
        next({ name: "login" });
    } else {
        next();
    }
});

trackRouter(router);

export default router;
