import { PiniaPluginContext } from "pinia";
import { Router } from "vue-router";
import { watch } from "vue";

export default (router: Router) => (context: PiniaPluginContext) => {
    // Watch store.jwt and redirect to login page if necessary
    watch(() => context.store.jwt, jwt => {
        if (jwt === undefined) {
            router.replace({ name: "login" });
        }
    });
}