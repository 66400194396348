<template>
    <div class="spinner" />
</template>
<style lang="scss" scoped>
.spinner {
    display: inline-block;
    width: 1em;
    height: 1em;
}

.spinner::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: 0.125em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite rotate;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}
</style>