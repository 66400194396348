/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";

// HACK: Recursively traverse entire response.data object and transform ISO8601
// date strings into proper JavaScript Date objects.

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

const isIsoDateString = (value: any) => {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

const convertDates = (o: any) => {
    if (o === null || o === undefined || typeof o !== "object") {
        return o;
    }
    for (const key of Object.keys(o)) {
        const value = o[key];
        if (isIsoDateString(value)) {
            o[key] = parseISO(value);
        } else if (typeof value === "object") {
            convertDates(value);
        }
    }
}

export default (response: AxiosResponse<any, any>): AxiosResponse<any, any> => {
    convertDates(response.data)
    return response;
}