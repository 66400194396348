import { defineStore } from "pinia";
import { GeoBoundingBox } from "@/models/geo-bounding-box";
import { SpotReport } from "@/models/spot-report";
import { LoginRequest } from "@/models/login-request";
import { WindForecast } from "@/models/wind-forecast";
import { TideForecast } from "@/models/tide-forecast";
import { SurfSpot } from "@/models/surf-spot";
import { WaveForecast } from "@/models/wave-forecast";
import { HudOverlayData } from "@/models/hud-overlay-data";
import _keyBy from "lodash/keyBy";
import _get from "lodash/get";
import _find from "lodash/find";
import objectHash from "object-hash";
import packageJson from "@/../package.json";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Mutex } from "async-mutex";
import { markRaw } from "vue";

class State {
    jwt: string | undefined = undefined;
    surfSpots: Record<string, SurfSpot> | undefined = undefined;
    currentSurfSpotId: string | undefined = undefined;
    pendingSurfSpotSlug: string | undefined = undefined;
    menuCollapsed = true;
    waveForecasts: Record<string, WaveForecast[]> = {};
    tideForecasts: Record<string, TideForecast[]> = {};
    windForecasts: Record<string, WindForecast[]> = {};
    spotReports: Record<string, SpotReport> = {};
    hudOverlayData: HudOverlayData | undefined = undefined;
    fetchSurfSpotsMutex = markRaw(new Mutex());
    fetchWaveForecastsMutex = markRaw(new Mutex());
    fetchTideForecastsMutex = markRaw(new Mutex());
    fetchWindForecastsMutex = markRaw(new Mutex());
    fetchSpotReportMutex = markRaw(new Mutex());
}

const useStore = defineStore("main", {
    persist: {
        key: objectHash(packageJson.version),
        paths: [
            "jwt",
            "currentSurfSpotId",
        ]
    },
    state: () => ({ ... new State() }),
    getters: {
        decodedJwt(state) {
            return state.jwt === undefined ? undefined : jwtDecode<JwtPayload>(state.jwt);
        },
        currentSurfSpot(state) {
            if (state.surfSpots === undefined || state.currentSurfSpotId == undefined) {
                return undefined;
            } else {
                return state.surfSpots[state.currentSurfSpotId];
            }
        },
        surfSpotById(state) {
            return (id: string) => state.surfSpots?.[id];
        },
        waveForecastsById(state) {
            return (id: string) => state.waveForecasts[id];
        },
        tideForecastsById(state) {
            return (id: string) => state.tideForecasts[id];
        },
        windForecastsById(state) {
            return (id: string) => state.windForecasts[id];
        },
        spotReportById(state) {
            return (id: string) => state.spotReports[id];
        }
    },
    actions: {
        async login({ username, password }: LoginRequest) {
            this.jwt = undefined;
            const value = await this.webApiClient.login(username, password);
            this.jwt = value.jwt;
        },
        async fetchSurfSpots(geoBoundingBox: GeoBoundingBox) {
            const release = await this.fetchSurfSpotsMutex.acquire();
            try {
                const cachedValue = this.surfSpots;
                if (cachedValue === undefined) {
                    const surfSpots = await this.webApiClient.getSurfSpots(geoBoundingBox.north, geoBoundingBox.south, geoBoundingBox.east, geoBoundingBox.west);
                    const currentSurfSpot =
                        _find(surfSpots, o => o.slug === this.pendingSurfSpotSlug) ??
                        _find(surfSpots, o => o.id === this.currentSurfSpotId) ??
                        _find(surfSpots, () => true);
                    const currentSurfSpotId = _get(currentSurfSpot, "id");
                    this.surfSpots = _keyBy(surfSpots, "id");
                    this.currentSurfSpotId = currentSurfSpotId;
                    this.pendingSurfSpotSlug = undefined;
                    this.hudOverlayData = undefined;
                }
            } finally {
                release();
            }
        },
        async fetchWaveForecasts(spotId: string) {
            const release = await this.fetchWaveForecastsMutex.acquire();
            try {
                const cachedValue = this.waveForecasts[spotId];
                if (cachedValue === undefined) {
                    const value = await this.webApiClient.getWaveForecast(spotId);
                    this.waveForecasts[spotId] = value;
                }
            } finally {
                release();
            }
        },
        async fetchTideForecasts(spotId: string) {
            const release = await this.fetchTideForecastsMutex.acquire();
            try {
                const cachedValue = this.tideForecasts[spotId];
                if (cachedValue === undefined) {
                    const value = await this.webApiClient.getTideForecast(spotId);
                    this.tideForecasts[spotId] = value;
                }
            } finally {
                release();
            }
        },
        async fetchWindForecasts(spotId: string) {
            const release = await this.fetchWindForecastsMutex.acquire();
            try {
                const cachedValue = this.windForecasts[spotId];
                if (cachedValue === undefined) {
                    const value = await this.webApiClient.getWindForecast(spotId);
                    this.windForecasts[spotId] = value;
                }
            } finally {
                release();
            }
        },
        async fetchSpotReport(spotId: string) {
            const release = await this.fetchSpotReportMutex.acquire();
            try {
                const cachedValue = this.spotReports[spotId];
                if (cachedValue === undefined) {
                    const value = await this.webApiClient.getSpotReport(spotId);
                    this.spotReports[spotId] = value;
                }
            } finally {
                release();
            }
        }
    },
});

export { useStore };