import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueGtag from "vue-gtag-next";
import piniaPluginRouter from "@/router/pinia-plugin-router";
import piniaPluginWebApiClient from "@/web-api-client/pinia-plugin-web-api-client"
import { WebApiClient } from "./web-api-client";

const webApiClient = new WebApiClient(process.env.VUE_APP_API_PREFIX as string);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(piniaPluginRouter(router));
pinia.use(piniaPluginWebApiClient(webApiClient));

createApp(App)
    .use(pinia)
    .use(router)
    .use(VueGtag, {
        property: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
        useDebugger: process.env.NODE_ENV === "development"
    })
    .mount("#app")
