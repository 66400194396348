const degToPct = (degrees: number): number => {
    return 100 * degrees / 360;
}

const degToRad = (degrees: number): number => {
    return degrees * Math.PI / 180;
}

const mod = (n: number, m: number): number => {
    return ((n % m) + m) % m;
};

const polarToRect = (radius: number, degrees: number): [number, number] => {
    const radians = degToRad(degrees);
    const x = radius * Math.cos(radians);
    const y = radius * Math.sin(radians);
    return [x, y];
}

const randomInRange = (min: number, max: number): number => {
    return Math.random() * (max - min) + min;
}

export {
    degToPct,
    degToRad,
    mod,
    polarToRect,
    randomInRange
}