<template>
    <div class="hud-overlay">
        <slot></slot>
    </div>
</template>
<style lang="scss" scoped>
.hud-overlay {
    display: inline-block;
    font-family: $font-family-monospace;
    font-size: $font-size-sm-1;
    font-weight: bold;
    background-color: rgba($black, 0.75);
    color: $green;
    border-radius: $border-radius-sm;
    padding: $spacer-1;
}
</style>