import { PiniaPluginContext } from "pinia";
import { WebApiClient } from "@/web-api-client";
import { markRaw, watch } from "vue";
import "pinia";

declare module "pinia" {
    export interface PiniaCustomProperties {
        webApiClient: WebApiClient
    }
}

export default (webApiClient: WebApiClient) => (context: PiniaPluginContext) => {
    // Make WebApiClient instance accessible on the store via this.webApiClient
    context.store.webApiClient = markRaw(webApiClient);

    // Watch store.jwt and keep webApiClient in sync
    watch(() => context.store.jwt, webApiClient.setJwt, { immediate: true });
}